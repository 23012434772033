import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable()
export class UsuariosService {
  constructor(private apiService: ApiService) { }

  save(params) {
    let token = localStorage.getItem('token_auth');
    if (params._id != null) {
      return this.apiService.put(`/users/${params._id}`, params, {
        Authorization: 'Bearer '+ token
      });
    }
    return this.apiService.post('/users', params, {
      Authorization: 'Bearer '+ token
    });
  }

  findCliente(id) {
    let token = localStorage.getItem('token_auth');
    return this.apiService.get(`/users/select/${id}`, {},  {
      Authorization: 'Bearer '+ token
    });
  }

  delete(id) {
    let token = localStorage.getItem('token_auth');
    return this.apiService.delete(`/users/${id}`, {}, {
      Authorization: 'Bearer '+ token
    });
  }

  retornaLista(params) {
    let token = localStorage.getItem('token_auth');
    return this.apiService.get('/users', params,  {
      Authorization: 'Bearer '+ token
    });
  }

  changePassword(params) {
    let token = localStorage.getItem('token_auth');
    return this.apiService.post('/users/change-password', params, {
      Authorization: 'Bearer '+ token
    });
  }
}
