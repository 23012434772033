import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public uiBasicCollapsed = false;
  public samplePagesCollapsed = false;
  public formComplete = false;
  user:any = {
    super: false
  };
  admin = false;
  constructor(private router: Router) {

    if(localStorage.getItem("currentUserDash")) {
      this.user = JSON.parse(localStorage.getItem("currentUserDash"));

      if (this.user.roles.find((data) => (data == 'administrador'))) {
        this.admin = true;
      }
    }
   }

  ngOnInit() {
    const body = document.querySelector('body');

    console.log('this.formComplete: ', this.formComplete)

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

  logout() {
    localStorage.removeItem("currentUserDash");
    this.router.navigate(['/user-pages/login']);
  }

}
