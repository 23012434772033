import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

    constructor() {

    }
    distance( distance: string ) {
        let result = parseFloat(parseFloat(distance).toFixed(1));
        if (result >= 1) {
          return result + ' km';
        }
        return (result * 1000) + 'm';
    }

    noPicture(event, type = 'default'): void {
      var img = event.srcElement;

      img.src = "assets/default/camera.jpg";

      img.onerror = null;// Control does not keep beating
    }
};
