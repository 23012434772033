import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [ToastrService]
})
export class DashboardComponent implements OnInit {

  model: NgbDateStruct;

  constructor(
    private spinner: NgxSpinnerService,
  ) { }

  async ngOnInit() {
    this.spinner.show();
    await this.loadTotal();
    this.spinner.hide();
  }

  date: Date = new Date();


  async loadTotal() {

  }

}
