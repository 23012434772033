import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig, UtilService]
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;

  user:any = {
    super: false,
  }
  constructor(config: NgbDropdownConfig, private router: Router,
    public utilService: UtilService) {
    config.placement = 'bottom-right';
    if(localStorage.getItem("currentUserDash")) {
      this.user = JSON.parse(localStorage.getItem("currentUserDash"));
    }
  }

  ngOnInit() {
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if(this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if(this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  // toggle right sidebar
  toggleRightSidebar() {
    document.querySelector('#right-sidebar').classList.toggle('open');
  }


  edit() {
    console.log(this.user)
    this.router.navigate(['/admin/edit/'+this.user.id]);
  }

  goToCalendar() {
    this.router.navigate(['/calendar']);
  }

  goToMyPassword() {
    this.router.navigate(['/changepassword']);
  }

  logout() {
    localStorage.removeItem("currentUserDash");
    this.router.navigate(['/user-pages/login']);
  }

}
