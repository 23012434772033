import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable()
export class ProjetoService {
  constructor(private apiService: ApiService) { }

  save(params) {
    let token = localStorage.getItem('token_auth');
    if (params._id != null) {
      return this.apiService.put(`/projeto/${params._id}`, params, {
        Authorization: 'Bearer '+ token
      });
    }
    return this.apiService.post('/projeto', params, {
      Authorization: 'Bearer '+ token
    });
  }

  findCliente(id) {
    let token = localStorage.getItem('token_auth');
    return this.apiService.get(`/projeto/cliente/${id}`, {},  {
      Authorization: 'Bearer '+ token
    });
  }


  find(id) {
    let token = localStorage.getItem('token_auth');
    return this.apiService.get(`/projeto/${id}`, {}, {
      Authorization: 'Bearer '+ token
    });
  }

  delete(id) {
    let token = localStorage.getItem('token_auth');
    return this.apiService.delete(`/projeto/${id}`, {}, {
      Authorization: 'Bearer '+ token
    });
  }

  retornaLista(params) {
    try {
      let token = localStorage.getItem('token_auth');
      return this.apiService.get('/projeto', {store_id: params},  {
        Authorization: 'Bearer '+ token
      }).catch((e) => {
        console.log(e)
        return false;
      });
    } catch (e) {
      return false;
    }
  }
}
