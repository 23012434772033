import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangepasswordComponent } from './pages/changepassword/changepassword.component';
import { ChatComponent } from './pages/chat/chat.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';


const routes: Routes = [
  { path: '', redirectTo: '/chat', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'chat', component: ChatComponent },
  { path: 'changepassword', component: ChangepasswordComponent },
  {
    path: 'preview',
    loadChildren: './pages/preview/preview.module#PreviewModule'
  },
  {
    path: 'swagger',
    loadChildren: './pages/swagger/swagger.module#SwaggerModule'
  },
  {
    path: 'clientes',
    loadChildren: './pages/clientes/clientes.module#ClientesModule'
  },
  {
    path: 'projeto',
    loadChildren: './pages/projetos/projetos.module#ProjetoModule'
  },
  {
    path: 'usuarios',
    loadChildren: './pages/usuarios/usuarios.module#UsuariosModule'
  },
  {
    path: 'intencao',
    loadChildren: './pages/intencao/intencao.module#IntencaoModule'
  },
  {
    path: 'user-pages',
    loadChildren: () => import('./user-pages/user-pages.module').then(m => m.UserPagesModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
