import { Injectable } from "@angular/core";
import { Http, Headers, RequestOptions } from "@angular/http";
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiService {
    constructor(private http: Http) { }
    post(api, params, header) {
        if (!header.Authorization) {
          header = this.getHeaders();
        }
        let options = new RequestOptions({ headers: header });
        let url = environment.url + api;
        return this.http.post(url, params, options).toPromise();
    }


    put(api, params, header) {
      if (!header) {
          header = this.getHeaders();
      }
      let options = new RequestOptions({ headers: header });
      let url = environment.url + api;
      return this.http.put(url, params, options).toPromise();
    }

    postUpload(api, params, header) {
        header = this.getHeadersUpload();
        let options = new RequestOptions({ headers: header });
        let url = environment.url + api;
        return this.http.post(url, params, options).toPromise();
    }

    get(api, params, header, apiDefault = true) {
        if (!header.Authorization) {
          header = this.getHeaders();
        }
        const options = new RequestOptions({ headers: header });
        let encodeParams = '';
        if (params) {
          encodeParams = this.prepareParams(params);
        }
        let url = environment.url + api + encodeParams;
        if(!apiDefault) {
            url = api;
        }
        return this.http.get(url, options).toPromise();
    }

    delete(api, params, header) {
        if (!header) {
            header = this.getHeaders();
        }
        let options = new RequestOptions({ headers: header });
        let encodeParams = this.prepareParams(params);
        let url = environment.url + api + encodeParams;
        return this.http.delete(url, options).toPromise();
    }

    getUri(api, params, header) {
        if (!header) {
            header = this.getHeaders();
        }
        let options = new RequestOptions({ headers: header });
        let encodeParams = this.prepareParams(params);
        let url = api + encodeParams;
        return this.http.get(url, options).toPromise();
    }

    prepareParams(params) {
        let str = [];

        if(Object.keys(params).length > 0) {
            for (var p in params) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
            }
        }

        return "?" + str.join("&");
    }


    getHeaders() {
        let user = JSON.parse(localStorage.getItem("currentUserDash"));

        if (user && user.token) {
            return new Headers({
                "Content-Type": "application/json",
                "Authorization": 'Bearer '+user.token
            });
        } else {
            return new Headers({
                "Content-Type": "application/json"
            });
        }
    }

    getHeadersUpload() {
        let user = JSON.parse(localStorage.getItem("currentUserDash"));

        if (user && user.token) {
            return new Headers({
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+user.token
            });
        }
    }
}
