import { Component, OnInit } from '@angular/core';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { ProjetoService } from './pages/projetos/projetos.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ProjetoService]
})
export class AppComponent implements OnInit {
  title = 'demo1';

  showSidebar = true;
  showNavbar = true;
  showFooter = true;
  isLoading: boolean;

  constructor(private router: Router, private projetoService: ProjetoService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if ((event.url === '/user-pages/login') || (event.url === '/user-pages/register') || (event.url === '/error-pages/404')
          || (event.url === '/error-pages/500') ) {
          this.showSidebar = false;
          this.showNavbar = false;
          this.showFooter = false;
          document.querySelector('.main-panel').classList.add('w-100');
          document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
          document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg', );
          document.querySelector('.content-wrapper').classList.remove('auth', 'lock-full-bg');
          if ((event.url === '/error-pages/404') || (event.url === '/error-pages/500')) {
            document.querySelector('.content-wrapper').classList.add('p-0');
          }
        } else {
          this.showSidebar = true;
          this.showNavbar = true;
          this.showFooter = true;
          document.querySelector('.main-panel').classList.remove('w-100');
          document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
          document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg');
          document.querySelector('.content-wrapper').classList.remove('p-0');
        }
      }
    });

    this.testRoute();
    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        //this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        //this.isLoading = false;
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  async testRoute() {
    console.log(localStorage.getItem('currentUserDash'));
    const jwtInfo = JSON.parse(localStorage.getItem('currentUserDash'));
    console.log('jwtInfo', jwtInfo);
    try{
      let projetct = await this.projetoService.retornaLista({});
      this.isLoading = false;
      if (projetct == false) {
        console.log('projetct-----', projetct)
        this.router.navigate(['/user-pages/login']);
      } else {
        if (!jwtInfo) {
          this.router.navigate(['/user-pages/login']);
        } else {
          this.router.navigate(['/chat']);
        }
      }
    } catch(e) {
      this.router.navigate(['/user-pages/login']);
    }

  }
}
