import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProjetosService } from 'src/app/services/projetos.service';
import { UsuariosService } from '../usuarios/usuarios.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss'],
  providers: [ToastrService, ProjetosService, UsuariosService]
})
export class ChangepasswordComponent implements OnInit {

  model: NgbDateStruct;
  projetos = [];
  formData:any = {

  }

  constructor(
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private projetoService: ProjetosService,
    private usuarioService: UsuariosService
  ) { }

  async ngOnInit() {
    this.spinner.show();
    await this.loadProject();
    this.spinner.hide();
  }

  date: Date = new Date();


  async loadProject() {
    let projects:any = await this.projetoService.list({});
    if (projects._body) {
      let result = JSON.parse(projects._body);
      console.log(result)
      if(result.length > 0) {
        this.projetos = result
      }
    }
  }

  async getConfig () {
    this.formData.titulo = null;
    this.formData._id = null;
    let config:any = await this.projetoService.config(this.formData.projeto);
    if (config._body) {
      let result = JSON.parse(config._body);
      this.formData.titulo = result.titulo;
      this.formData._id = result._id;
    }

  }
  async save() {
    if (await this.validate()) {
      try {
          this.spinner.show();
          const result = await this.usuarioService.changePassword(this.formData);
          const data = result.json();
          this.spinner.hide();
          if (!data.sucesso ) {
              return this.toastrService.error("Erro ao editar senha");
          }
          this.formData = {};
          this.toastrService.success('Senha atualizada com sucesso.');
      } catch (error) {
        this.spinner.hide();
          return this.toastrService.error("Erro ao editar senha");
      }
    }
  }

  async validate() {
    if (!this.formData.password) {
        this.toastrService.error('O campo senha é obrigatorio!');
        return false;
    }
    if (!this.formData.newPassword) {
        this.toastrService.error('O campo nova senha é obrigatorio!');
        return false;
    }
    if (!this.formData.confPassword) {
        this.toastrService.error('O campo confirmar senha é obrigatorio!');
        return false;
    }

    if (this.formData.new_password != this.formData.conf_password) {
      this.toastrService.error('O campo senha deve ser igual ao confirmar senha!');
      return false;
    }
    return true;
  }

}
