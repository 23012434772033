import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class ProjetosService {

    constructor(private apiService: ApiService) {

    }

    list(params) {
        let token = localStorage.getItem('token_auth');
        return this.apiService.get('/projeto', params,  {
          Authorization: 'Bearer '+ token
        })
    }

    config(id) {
      let token = localStorage.getItem('token_auth');
        return this.apiService.get('/configura/projeto/'+id, {},  {
          Authorization: 'Bearer '+ token
        })
    }

    save(params) {
      let token = localStorage.getItem('token_auth');
      if (params._id) {
        return this.apiService.put('/configura/'+params._id, params,  {
          Authorization: 'Bearer '+ token
        })
      }else {
        delete params._id
        return this.apiService.post('/configura', params,  {
          Authorization: 'Bearer '+ token
        })
      }
    }

}
