import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProjetosService } from 'src/app/services/projetos.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [ToastrService, ProjetosService]
})
export class ChatComponent implements OnInit {

  model: NgbDateStruct;
  projetos = [];
  formData:any = {
    projeto: ''
  }

  constructor(
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private projetoService: ProjetosService,
  ) { }

  async ngOnInit() {
    this.spinner.show();
    await this.loadProject();
    this.spinner.hide();
  }

  date: Date = new Date();


  async loadProject() {
    let projects:any = await this.projetoService.list({});
    if (projects._body) {
      let result = JSON.parse(projects._body);
      console.log(result)
      if(result.length > 0) {
        this.projetos = result
      }
    }
  }

  async getConfig () {
    this.formData.titulo = null;
    this.formData._id = null;
    let config:any = await this.projetoService.config(this.formData.projeto);
    if (config._body) {
      let result = JSON.parse(config._body);
      this.formData.titulo = result.titulo;
      this.formData._id = result._id;
    }

  }
  async save() {
    if (await this.validate()) {
      try {
          this.spinner.show();
          const result = await this.projetoService.save(this.formData);
          const data = result.json();
          if (!data._id ) {
              return this.toastrService.error("Erro no cadastro de produto");
          } else {
            this.formData._id = data._id;
          }

          this.spinner.hide();
          this.toastrService.success('Projeto atualizado com sucesso.');
      } catch (error) {
          return this.toastrService.error("Erro no atualizar o projeto");
      }
    }
  }

  async validate() {
    if (!this.formData.titulo) {
        this.toastrService.error('O campo titulo é obrigatorio!');
        return false;
    }
    return true;
  }

}
